<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import { cards } from '@design-system/mocks/productTile'
import { isEqual } from 'ufo'

const { myAccountTabsWithoutForYou } = useShortcuts()
const topHeader = ref<HTMLElement>()
const { height } = useElementSize(topHeader)
const { guestWishlist, myAccount } = useRouteHelper()
const { topHeaderHeight: topHeaderHeightState } = useHeaderAndNavbarHeight()
const { lastAddedToCart } = useAddToCart()
const { breakpoint } = useProjectBreakpoint()
const isMobile = breakpoint.smaller('md')
const route = useRoute()
const router = useRouter().currentRoute

const { isLogged } = useLogin()
const localePath = useLocalePath()
const { openDialog: openLoginSidebar } = useDialog(SIDEBAR_IDS.loginSidebar)
const { dispatchAccountMenuEvent } = useGAAccountEvents()

const isOverviewPage = computed(() => isEqual(route.path, myAccount))
const isGuestWishlistPage = computed(() => isEqual(route.path, guestWishlist))

const showChips = computed(
  () =>
    (route.fullPath.includes(myAccount) || isGuestWishlistPage.value) &&
    !route.path.includes('return') &&
    !route.path.includes('thank-you')
)

const currentPagePath = ref<string>('')

watch(
  height,
  (val, oldVal) => {
    if (val !== oldVal) {
      topHeaderHeightState.value = val
    }
  },
  { immediate: true }
)

watch(isLogged, async () => {
  if (!isLogged.value) {
    await navigateTo(localePath('/signup'))
  }
})

watch(
  height,
  (val, oldVal) => {
    if (val !== oldVal) {
      topHeaderHeightState.value = val
    }
  },
  { immediate: true }
)

const handleShortcutClick = (text: string) => {
  dispatchAccountMenuEvent(text)
  if (isLogged.value) return
  openLoginSidebar()
}
// this watch gives the updated path in real time
watch(
  route,
  () => {
    currentPagePath.value = router.value.path
  },
  { immediate: true }
)
</script>

<template>
  <div class="theme-global">
    <HeaderWrapperLogic>
      <template #header="props">
        <HeaderDefault
          class="print:hidden"
          v-bind="{
            ...props,
            currentBrand: 'global',
            deferredCurrentBrand: 'global',
            staticOnMobile: true,
          }"
        />
      </template>
      <template #navigation="props">
        <HeaderNavigationBar v-bind="props" />
      </template>
    </HeaderWrapperLogic>
    <div class="h-full w-full md:mb-0">
      <div
        class="grid-standard bg-primitives-off-white py-lg md:py-xl lg:py-4xl print:py-0"
      >
        <main id="main" class="columns-standard">
          <div
            v-if="showChips"
            class="gap-sm md:gap-md flex flex-col print:hidden"
          >
            <MyAccountNavigationHeadline
              :hierarchy="
                isOverviewPage || isGuestWishlistPage ? 'parent' : 'child'
              "
              :label="$ts('myAccount.logged.overview')"
              :to="myAccount"
            />
            <div class="gap-xs flex w-full items-center print:hidden">
              <AtomsShortcut
                v-for="shortcut in myAccountTabsWithoutForYou"
                :key="shortcut.text"
                :link="shortcut.link"
                :size="isMobile ? 'S' : 'L'"
                :class="[isGuestWishlistPage && 'cursor-pointer']"
                bg-color="white"
                is-uppercase
                class="flex-1"
                @link-click="handleShortcutClick(shortcut.text)"
              >
                <template #icon>
                  <component :is="shortcut.icon" aria-hidden="true" />
                </template>
                <template #text>
                  <p>{{ shortcut.text }}</p>
                </template>
              </AtomsShortcut>
            </div>
          </div>
          <slot />
        </main>
      </div>
    </div>
    <div class="mb-[var(--navbar-height)] lg:mb-0 print:hidden">
      <FooterMain />
    </div>
    <div class="grid-standard fixed inset-x-0 top-4 z-50 lg:top-20">
      <WishlistNotifications class="gap-y-md columns-standard grid" />
    </div>
    <CartFeedbackAddToCart :is-already-added="lastAddedToCart?.isAlreadyAdded">
      <template v-if="lastAddedToCart" #product>
        <UIProductCardHorizontal v-bind="lastAddedToCart" />
      </template>
      <template v-if="lastAddedToCart" #relatedProducts>
        <div class="grid grid-cols-12">
          <UIProductTile
            v-for="(card, index) in cards"
            :key="index"
            v-bind="card"
            :price="{
              originalPrice: lastAddedToCart.info.price,
            }"
            class="col-span-6"
          />
        </div>
      </template>
    </CartFeedbackAddToCart>
    <GlobalSlides />
    <GlobalSnackbars />
  </div>
</template>
